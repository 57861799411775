import {
  Body1,
  Button,
  Text,
  Subtitle1,
  Card,
  CardFooter,
  CardHeader,
  Image,
  Popover,
  PopoverTrigger,
  PopoverSurface
} from "@fluentui/react-components";
import {
  CalendarLtr16Regular,
  TargetArrow16Regular,
  FlashFlow16Regular,
  Open16Filled,
  MoreHorizontalRegular
} from "@fluentui/react-icons";
import { dialog,DialogDimension, DialogSize  } from '@microsoft/teams-js';
import React, { useContext} from "react";
import { TeamsFxContext } from '../Context';
import { WorkflowItem } from "../../types/WorkflowType";
import WorkflowTaskImage from "./WorkflowTaskImage";
import { utcDateTimeToUserDateTime } from "../../services/utility";
import { logger } from '../../Logger';
import defaultItem  from '../../Images/default-item.png';
import { AnalyticsManager } from "../../services/analyticsUtility";
import AnalyticsConstants from "../common/AnalyticsConstants.json";

interface CardProps {
  workflowTask: WorkflowItem;
  isOpenInTcButtonEnabled: boolean;
}

const WorkflowTaskCard: React.FunctionComponent<CardProps> = ({
  workflowTask,
  isOpenInTcButtonEnabled
}: CardProps) => {

  const teamsContext = useContext(TeamsFxContext);
  const imageTicket: string | undefined = workflowTask.target?.[0]?.target_image_ticket?.uiValues[0];

  const onViewWorkflowTaskInTCClicked = async () => {
    logger.logTrace("Entered onViewWorkflowTaskInTCClicked");
    const analyticsManager = AnalyticsManager.getInstance();
    analyticsManager.logEvent(AnalyticsConstants.openWorkflowInTc);
    const uri = workflowTask.teamcenterUri? (workflowTask.teamcenterUri + `?uid=` + workflowTask.uid) : null;
    const message = uri ? "Opening Workflow Task in Teamcenter" : "No URL present for this Workflow Task";
    logger.logInformation(message);
    uri && window.open(uri, "_blank");
  };

  const openWorkflowInfoDialog = () => {
    logger.logTrace("Entered openWorkflowInfoDialog");
    const analyticsManager = AnalyticsManager.getInstance();
    analyticsManager.logEvent(AnalyticsConstants.cmdOpenWorkflowTask);
    let dialogBoxSize: DialogSize;
    if(isOpenInTcButtonEnabled === true ){
      dialogBoxSize = {
        height: DialogDimension.Large,
        width: window.innerWidth > 1000 ? 450: 350,
      }
    }
    else{
      dialogBoxSize = {
        height: 600,
        width: 350
      }
    }
    const taskInfo = {
      title: 'Workflow Task',
      url: window.location.origin + '/index.html#/workflowtaskinfo/' + workflowTask.uid +'/'+ workflowTask.type,
      card: null,
      size: dialogBoxSize,
    };

    dialog.url.open(taskInfo, () => teamsContext.queryClient.invalidateQueries());
    logger.logTrace("Exit openWorkflowInfoDialog")
  };

  const renderTargetLink = (targetObject: {
    target_uid: string; target_id?: { dbValues: [string]; uiValues: [string]; }; target_name: any; target_image_ticket?: { dbValues: [string]; uiValues: [string]; }; 
  }) => {
    const targetObjectUri = workflowTask.teamcenterUri + `?uid=` + targetObject.target_uid;
    return isOpenInTcButtonEnabled ? (
      <a href={targetObjectUri} target="_blank" rel="noreferrer">
        {targetObject.target_name.uiValues[0]}
      </a>
    ) : (
      targetObject.target_name.uiValues[0]
    );
  };

  const renderTargetObjects = (workflowTask:any) => {
    if(workflowTask.target){
      if(workflowTask.target.length === 1){
        return renderTargetLink(workflowTask.target[0]);
      }
      else{
        return (
          <span>
            {renderTargetLink(workflowTask.target[0])}
            ,&nbsp;
            <Popover withArrow closeOnScroll>
              <PopoverTrigger disableButtonEnhancement>
                <MoreHorizontalRegular />
              </PopoverTrigger>
              <PopoverSurface tabIndex={-1}>
              <span style={{ listStyleType: 'none', padding: 0 }}>
                {workflowTask.target.map((targetObject:any, index:number) => (
                  <li key={index}>
                    {renderTargetLink(targetObject)}
                  </li>
                ))}
              </span>
              </PopoverSurface>
            </Popover>
          </span>
        );
      }
    }
    else {
      return <span>No target items</span>;
    }
  }

  return (
    <div className="workflow-task-container">
      <Card>
        <CardHeader
          className="workflow-task-header"
          header={
            <>
              <div className="workflow-card-image-container">
                {imageTicket?(
                  <WorkflowTaskImage
                    id={workflowTask.target?.[0]?.target_id?.uiValues[0]}
                    imageTicket={imageTicket}
                  />
                ):(
                  <div className="workflow-task-image"><Image src={defaultItem} style={{ maxWidth: '100%', maxHeight: '100%' }} /></div>
                )}
              </div>
            </>
          }
        />

        <CardHeader
          className="workflow-card"
          header={
            <Body1>
              <Subtitle1
                className="workflow-task-synopsis"
                truncate
                wrap={false}
              >
                {workflowTask.props.object_name.uiValues[0]}
              </Subtitle1>
              <div className="workflow-name-container">
                <FlashFlow16Regular
                  className="workflow-calendar-icon"
                  fontSize={16}
                />
                <Text className="workflow-name-value">
                  Workflow:&nbsp;{workflowTask.props.job_name.uiValues[0].split(':')[0]}
                </Text>
              </div>
              <div className="workflow-name-container">
                <TargetArrow16Regular
                  className="workflow-name-icon"
                  fontSize={16}
                />
                <Text className="workflow-target-value" wrap={false}>
                  <div>
                    Target objects:&nbsp;
                    {renderTargetObjects(workflowTask)}
                  </div>
                </Text>
              </div>
              <div className="workflow-calendar-container">
                <CalendarLtr16Regular
                  className="workflow-calendar-icon"
                  fontSize={16}
                />
                <Text className="workflow-calendar-value">
                  Start date:&nbsp;
                  {utcDateTimeToUserDateTime(workflowTask.props.fnd0StartDate.uiValues[0])}
                </Text>
              </div>
              <div className="workflow-calendar-container">
                <CalendarLtr16Regular
                  className="workflow-calendar-icon"
                  fontSize={16}
                />
                <Text className="workflow-calendar-value">
                  Due date:&nbsp;
                  {utcDateTimeToUserDateTime(workflowTask.props.due_date.uiValues[0])}
                </Text>
              </div>
            </Body1>
          }
        />

        <CardFooter className="card-footer">
          {isOpenInTcButtonEnabled && <Button onClick={onViewWorkflowTaskInTCClicked} icon={<Open16Filled />} iconPosition="after" className="open-in-button">Open in Teamcenter</Button>}
          <Button
            appearance="primary"
            onClick={openWorkflowInfoDialog}
          >
            Open
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
};

export default WorkflowTaskCard;

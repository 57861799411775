import {
    Button,
    DialogActions,
    DialogBody,
    DialogContent,
    Label,
    Textarea,
    Checkbox,
    Spinner,
    InputOnChangeData
  } from "@fluentui/react-components";
  import {
    Stack
  } from "@fluentui/react";
  import * as React from "react";
  import { useParams } from 'react-router';
  import { UseMutationTypeQuery } from "../../types";
  import {
    TCDataTypes,
    TCInputType,
    TCInputTypeUtils,
  } from "../../services/TCInputTypeUtils";
  import { dialog } from "@microsoft/teams-js";
  import { IGetDeclarativeStyleSheetsRequestData, StyleSheetType, IModelObject, IPerformActionRequestData, PerformActionRequestData, ModelObject} from "../common/TcSOATypes";
  import { logger } from "../../Logger";
  import { useGetDeclarativeStyleSheet } from "../../hooks/useGetDeclarativeStyleSheet";
  import { usePerformAction } from "../../hooks/usePerformAction";
  import { Constants } from "../common/Constants";
  import * as microsoftTeams from '@microsoft/teams-js';
  import { AnalyticsManager } from "../../services/analyticsUtility";
  import { TeamsFxContext } from "../Context";
  import AnalyticsConstants from "../common/AnalyticsConstants.json";
  import { WorkflowUtils } from "./WorkflowUtils";
  
  type WorkflowTaskInfoParams = {
    uid: string;
    type: string;
  };

  type LabelIndex = {
    index: string;
    label: string;
  };

  const WorkflowTaskInfo: React.FunctionComponent = () => {
    const teamsContext = React.useContext(TeamsFxContext);
    // Logging workflow task info panel visit in analytics
    React.useEffect( () => {
      async function startProcessing() {
        if (teamsContext.configuration) { 
          const analyticsManager = AnalyticsManager.getInstance();
          analyticsManager.logPlace(AnalyticsConstants.panelWorkflowTasksInfo);
        }
      }
      startProcessing();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Initalize teams app and notify success of loading
    React.useEffect(() => {
      (async () => {
        await microsoftTeams.app.initialize();
        microsoftTeams.app.notifySuccess();
      })();
    }, []);

    const workflowTask: any = useParams<WorkflowTaskInfoParams>();

    // Use State Parameters
    const [tcInputFields, setInputFields] = React.useState<TCInputType[]>([]);
    const [updatedFields, setUpdatedFields] = React.useState<string[]>([]);
    const [updatingWorkflowTask, setUpdatingWorkflowTask] = React.useState<boolean>(false);
    const [actionbleObjectForSignoffTask, setActionbleObjectForSignoffTask] = React.useState<IModelObject>();
    const signOffButtonLabels = React.useRef<LabelIndex[]>([]);
    const conditionTaskLabels = React.useRef<string[]>([]);

    const getPropertyNameValues = () => {
      let propertyNameValue = {};
      if(updatedFields.length>0) {
        const index: number = updatedFields.indexOf(Constants.comments);
        if(index >= 0){
          const addedComment = tcInputFields.find(input => input.propertyName === Constants.comments);
          propertyNameValue = {comments:[addedComment?.value]};
        }
      }
      return propertyNameValue;
    }

    const getPerformSearchInput = (actionName: string, supportingValue:string) =>{
      let actionableObject: IModelObject;
      let supportingObject: IModelObject;
      if (workflowTask.type === Constants.signoff) {
          actionableObject = actionbleObjectForSignoffTask;
          supportingObject = {
            uid: workflowTask.uid,
            type: workflowTask.type,
            className: workflowTask.type,
            objectId: workflowTask.uid
          };
      } else {
        actionableObject = {
          uid: workflowTask.uid,
          type: workflowTask.type,
          className: workflowTask.type,
          objectId: workflowTask.uid
        };
        supportingObject = Constants.dummyBusinessObject;
      }

      const inputData: IPerformActionRequestData = {
        action: actionName,
        actionableObject: actionableObject,
        propertyNameValues: getPropertyNameValues(),
        signatures:[],
        clientId: '',
        password: '',
        supportingObject: supportingObject,
        supportingValue: supportingValue
      };
      return [inputData];
    };

    // API Mutations
    const getStyleSheet: UseMutationTypeQuery<IGetDeclarativeStyleSheetsRequestData, any> = useGetDeclarativeStyleSheet();
    const performAction: UseMutationTypeQuery<PerformActionRequestData[], any> = usePerformAction();

    // Use Effect Hook
    React.useEffect( () => {
      async function startProcessing() {
        const businessObject: ModelObject = {
          uid: workflowTask.uid,
          type: workflowTask.type,
          className: '',
          objectId: ''
        };
        const requestData: IGetDeclarativeStyleSheetsRequestData = TCInputTypeUtils.getXRTRequestData(workflowTask.type, StyleSheetType.INFO, businessObject);
        if (requestData) {
          const xrtResponse = await getStyleSheet.mutateAsync(requestData);
          if (xrtResponse) {
            if (workflowTask.type === Constants.signoff) {
              signOffButtonLabels.current = WorkflowUtils.getDecisionLabels(xrtResponse.modelObject,workflowTask.uid);
              const modelObjects = xrtResponse.modelObject;
              for (const key in modelObjects) {
                if (modelObjects[key].type === Constants.EPMPerformSignoffTask) {
                  const actionableObject: IModelObject = {
                    uid: modelObjects[key].uid,
                    className: modelObjects[key].className,
                    objectId: modelObjects[key].objectId,
                    type: modelObjects[key].type                  };
                  setActionbleObjectForSignoffTask(actionableObject);
                }
              }
            }
            else if(workflowTask.type === Constants.EPMConditionTask){
              conditionTaskLabels.current = await WorkflowUtils.getConditionTaskLabels(xrtResponse.modelObject,workflowTask.uid,teamsContext);
            }
            const tcInputTypes: TCInputType[] = TCInputTypeUtils.extractTCInputTypes(xrtResponse.viewModel, xrtResponse.modelObject);
            setInputFields(tcInputTypes);
          }
        }
      }
      startProcessing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Form Action Handlers

    const CloseWorkflowTask = () => {
      dialog.url.submit(undefined);
    };

    // Form Action Handlers
    const handleApproveSignoffTaskAction = async() => {
      logger.logTrace(`Entered ${handleApproveSignoffTaskAction.name}`);
      const requestData: PerformActionRequestData[] = getPerformSearchInput(Constants.SOA_EPM_approve_action,Constants.SOA_EPM_approve);
      setUpdatingWorkflowTask(true);
      // calling the backend API for Approving WF task.
      if (requestData) {
        const response = await performAction.mutateAsync(requestData);
        setUpdatingWorkflowTask(false);
        if (response.isError) {
          logger.logError("Error:" + response.error);
        } else {
          CloseWorkflowTask();
        }
      } else {
        throw new Error("Failed to create request data for Approving Workflow Task.");
      }
      logger.logTrace(`Exit ${handleApproveSignoffTaskAction.name}`);
    }

    const handleRejectSignoffTaskAction = async() => {
      logger.logTrace(`Entered ${handleRejectSignoffTaskAction.name}`);
      const requestData: PerformActionRequestData[] = getPerformSearchInput(Constants.SOA_EPM_reject_action,Constants.SOA_EPM_reject);
      setUpdatingWorkflowTask(true);
      // calling the backend API for Rejecting WF task.
      if (requestData) {
        const response = await performAction.mutateAsync(requestData);
        setUpdatingWorkflowTask(false);
        if (response.isError) {
          logger.logError("Error:" + response.error);
        } else {
          CloseWorkflowTask();
        }
      } else {
        throw new Error("Failed to create request data for Rejecting Workflow Task.");
      }
      logger.logTrace(`Exit ${handleRejectSignoffTaskAction.name}`);
    }

    const handleCompleteDoTaskAction = async() => {
      logger.logTrace(`Entered ${handleCompleteDoTaskAction.name}`);
      setUpdatingWorkflowTask(true);
      //modified the input for EPMConditionTask and EPMDoTask
      const supportingValue = (workflowTask.type === Constants.EPMConditionTask)? Constants.Complete : Constants.SOA_EPM_completed;
      const requestData: PerformActionRequestData[] = getPerformSearchInput(Constants.SOA_EPM_complete_action,supportingValue);
      // calling the backend API for completing WF task
      if (requestData) {
        const response = await performAction.mutateAsync(requestData);
        setUpdatingWorkflowTask(false);
        if (response.isError) {
          logger.logError("Error:" + response.error);
        } else {
          CloseWorkflowTask();
        }
      } else {
        throw new Error("Failed to create request data for Completing Workflow Task.");
      }
      logger.logTrace(`Exit ${handleCompleteDoTaskAction.name}`);
    };

    const handleConditionTaskComplete = async(label:string) => {
      logger.logTrace(`Entered ${handleConditionTaskComplete.name}`);
      setUpdatingWorkflowTask(true);
      //modified the input for EPMConditionTask and EPMDoTask
      const supportingValue = label;
      const requestData: PerformActionRequestData[] = getPerformSearchInput(Constants.SOA_EPM_complete_action,supportingValue);
      // calling the backend API for completing WF task
      if (requestData) {
        const response = await performAction.mutateAsync(requestData);
        setUpdatingWorkflowTask(false);
        if (response.isError) {
          logger.logError("Error:" + response.error);
        } else {
          CloseWorkflowTask();
        }
      } else {
        throw new Error("Failed to create request data for Completing Workflow Task.");
      }
      logger.logTrace(`Exit ${handleConditionTaskComplete.name}`);
    };

    // Input Field Type Handlers/Updaters

    const handleConditionTaskClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      const labelClicked = event.currentTarget.textContent || '';
      logger.logTrace(`Entered ${handleConditionTaskClick.name}`);
      logger.logInformation('Action Complete EPMConditionTask Task.');
      const analyticsManager = AnalyticsManager.getInstance();
      analyticsManager.logEventwithProperty(AnalyticsConstants.eventWorkflow, AnalyticsConstants.propValComplete);
      if(labelClicked !== ''){
        handleConditionTaskComplete(labelClicked);
      }
      logger.logTrace(`Exit ${handleConditionTaskClick.name}`);
    };
  
    const handleStringPropertyChange = (ev: React.ChangeEvent, data: InputOnChangeData) => {
      logger.logTrace(`Entered ${handleStringPropertyChange.name}`);
      const updatedInputs = tcInputFields.map((input) => {
        if (input.propertyName === ev.target.id) {
          return {
            ...input,
            value: data.value,
          };
        }
        return input;
      });
      setInputFields(updatedInputs);
      const index: number = updatedFields.indexOf(ev.target.id);
      if (index === -1) {
        const newUpdatedFields = [...updatedFields, ev.target.id];
        setUpdatedFields(newUpdatedFields);
      }
      logger.logTrace(`Exit ${handleStringPropertyChange.name}`);
    };

    const handleApprove = () => {
      logger.logTrace(`Entered ${handleApprove.name}`);
      logger.logInformation('Action approve Signoff Task.');
      const analyticsManager = AnalyticsManager.getInstance();
      analyticsManager.logEventwithProperty(AnalyticsConstants.eventWorkflow, AnalyticsConstants.propValApprove);
      handleApproveSignoffTaskAction();
      logger.logTrace(`Exit ${handleApprove.name}`);
    };

    const handleReject = () => {
      logger.logTrace(`Entered ${handleReject.name}`);
      logger.logInformation('Action Reject Signoff Task.');
      const analyticsManager = AnalyticsManager.getInstance();
      analyticsManager.logEventwithProperty(AnalyticsConstants.eventWorkflow, AnalyticsConstants.propValReject);
      handleRejectSignoffTaskAction();
      logger.logTrace(`Exit ${handleReject.name}`);
    };

    const handleComplete = () => {
      logger.logTrace(`Entered ${handleComplete.name}`);
      logger.logInformation('Action Complete EPMDoTask Task.');
      const analyticsManager = AnalyticsManager.getInstance();
      analyticsManager.logEventwithProperty(AnalyticsConstants.eventWorkflow, AnalyticsConstants.propValComplete);
      handleCompleteDoTaskAction();
      logger.logTrace(`Exit ${handleComplete.name}`);
    };
    // UI Render Components

    const renderPerformSignOffButtons = () => {
      return (
        <>
          {signOffButtonLabels.current.map((buttonLabel) => (
            <Button
              key={buttonLabel.label}
              appearance="primary"
              onClick={ (buttonLabel.index === '89')? handleApprove : handleReject}
            >
              {buttonLabel.label}
            </Button>
          ))}
        </>
      );
    };

    const renderConditionTaskButtons = () => {
      return (
        <>
          {conditionTaskLabels.current.map((buttonLabel) => (
            <Button
              key={buttonLabel}
              appearance="primary"
              onClick={handleConditionTaskClick}
            >
              {(buttonLabel === 'true') ? 'True' : (buttonLabel === 'false') ? 'False' : buttonLabel}
            </Button>
          ))}
        </>
      );
    };

    const renderWidget = () => {
      logger.logTrace(`Entered ${renderWidget.name}`);
      const propList = tcInputFields.map((prop) => {
        if (prop.type === TCDataTypes.String) {
          return (
            <>
              {(prop.propertyName === Constants.comments && workflowTask.type !== Constants.EPMSelectSignoffTask)?(
                <><Label htmlFor={prop.propertyDisplayName} className='label-width'>
                  {prop.propertyDisplayName}
                </Label>
                <Textarea
                  //disabled = {!inEditMode}
                  size="large"
                  resize="vertical"
                  id={prop.propertyName}
                  value={prop.value}
                  onChange={handleStringPropertyChange}
                /></>):(
                  <Stack horizontal>
                  <Label htmlFor={prop.propertyDisplayName} className='label-width'>
                    {prop.propertyDisplayName}
                  </Label><Label>
                    {prop.value}
                  </Label>
                  </Stack>
                )
              }
            </>
          );
        } else if (prop.type === TCDataTypes.Boolean) {
          return (
            <>
            <Checkbox label={prop.propertyDisplayName} checked={prop.value === 'true' ? true: false} id={prop.propertyName}/>
            </>
          );
        } else if (prop.type === TCDataTypes.Date) {
          return (
            <Stack horizontal>
              <Label htmlFor={prop.propertyDisplayName} className='label-width'>
                {prop.propertyDisplayName}
              </Label>
              <Label>
                {prop.value}
              </Label>
            </Stack>
          );
        } else if (prop.type === TCDataTypes.Integer) {
          return (
            <Stack horizontal>
              <Label htmlFor={prop.propertyDisplayName} className='label-width'>
                {prop.propertyDisplayName}
              </Label>
              <Label>
                {prop.value}
              </Label>
            </Stack>
          );
        } else if (prop.type === TCDataTypes.UntypedReference || prop.type === TCDataTypes.Object) {
          return (
            <Stack horizontal>
              <Label htmlFor={prop.propertyDisplayName} className='label-width'>
                {prop.propertyDisplayName}
              </Label>
              <Label>
                {prop.value}
              </Label>
            </Stack>
          );
        }
        else {
          return <></>;
        }
      });
      logger.logTrace(`Exit ${renderWidget.name}`);
      return propList;
    };

    const renderSpinner = (isHidden: boolean, label: string) => {
      return (
        <div hidden={isHidden}>
          <Spinner appearance="primary" label={label} />
        </div>
      );
    };

    const getLoadingMessage = () => {
      if (getStyleSheet.isLoading) {
        return  <Spinner appearance="primary" label="Loading the Workflow Task Info panel." />
      }
    }
    // WorkflowTaskInfo renderer method.
    return (
      <>
        <div hidden={tcInputFields.length > 0}>
          {getLoadingMessage()}
        </div>
        <div hidden={tcInputFields.length === 0}>
          { !updatingWorkflowTask ? (
              <form className="workflow-task-formBody">
                <DialogBody>
                <DialogContent className="workflow-task-formContainer">
                    {renderWidget()}
                </DialogContent>
                <DialogActions>
                  <div className="workflow-task-dialog-action">
                    <Button appearance="secondary" onClick={CloseWorkflowTask} >
                      Close
                    </Button>
                    {
                      (workflowTask.type === Constants.signoff)?(
                        renderPerformSignOffButtons()
                      ): ((workflowTask.type === Constants.EPMDoTask)? (
                        <Button appearance="primary" onClick={handleComplete}>Complete</Button>
                        ):((workflowTask.type === Constants.EPMConditionTask)? (
                          renderConditionTaskButtons()
                          ): (<></>)
                        ))
                    }
                  </div>
                </DialogActions>
                </DialogBody>
              </form>
            ): (
            <>{renderSpinner(false, "Updating the Workflow Task")}</>
            )
          }
        </div>
      </>
    );
  };
  export default WorkflowTaskInfo;
